import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { store } from '../store';
import { getConsentFromLocalStorage, saveConsentToLocalStorage } from './localStorage';
import isEqual from 'lodash/isEqual';
import { getSearchParam, gtagConsentParamValues, searchParams } from './searchParams';

const uaGroup = 'group1';
const ga4Group = 'group2';

let clients = [];
let _accountId;
let _tourId;

const dimensions = {
  dimension1: 'account_id',
  dimension2: 'tour_id',
  dimension3: 'stop_number',
  dimension4: 'stop_name',
  dimension5: 'percent_360_video',
  dimension6: 'resolution_360_video',
  dimension7: 'current_bandwidth',
  dimension8: 'length_360_video',
  dimension9: 'size_360_video',
  dimension10: 'first_frame_360_video',
  dimension11: 'tour_name',
};

// need to add any custom dimensions or metrics here
const allowedEventKeys = [
  'event_category',
  'event_label',
  'nonInteraction',
  'transport',
  'value',
  ...Object.values(dimensions),
];

export const GA_EVENTS = {
  AUDIO: 'Audio',
  AUDIO_TRANSCRIPT: 'Audio Transcript',
  AUTO_ROTATE: 'Auto-Rotate',
  BUTTON_CLICK: 'Button Click',
  COPY: 'Copy',
  FORM_AUTO_OPEN: 'Form Auto-Open',
  FORM_BUTTON: 'Form Button',
  FORM_CLOSED: 'Form Closed',
  FORM_ENGAGE: 'Engage with Form',
  FULL_SCREEN: 'Full Screen',
  GALLERY_CLICK: 'Concept3D Gallery',
  HOTSPOT_AUDIO_MUTE: 'Hotspot (Audio) - Mute',
  HOTSPOT_AUDIO_PLAY: 'Hotspot (Audio) - Play',
  HOTSPOT_AUDIO_TRANSCRIPT: 'Hotspot (Audio) - Open Transcript',
  HOTSPOT_LINK_EXTERNAL: 'Hotspot (Link) - External',
  HOTSPOT_LINK_STOP: 'Hotspot (Link) - Additional Stop',
  HOTSPOT_VIDEO_PLAY: 'Hotspot (Video) - Play',
  LOGO_SELECT: 'Select Logo',
  MAP_MINIMIZE: 'Minimize Map',
  MAP_TYPE_SWITCH: 'Map type switch',
  MEDIA_INFO_OPEN: 'Open Additional Media',
  MEDIA_MINIMIZE: 'Minimize Media',
  MOBILE_BUTTON_LIST: 'Mobile Button List',
  SHARE_TOUR: 'Share Tour',
  STOP_VIEW: 'View Tour Stop',
  SWITCH_TOUR_VIEW: 'Switch Tour View',
  TOUR_VIEW: 'View Tour',
  TRIAL_EXPLORE_FULL: 'Explore Full Functionality',
  TRIAL_MAKE_OWN_TOUR: 'Make your own tour',
  VIDEO_360_ADAPTIVE_QUALITY_CHANGE: 'Changed Adaptive Streaming Quality',
  VIDEO_360_COMPLETE: '360º Video Complete',
  VIDEO_360_FIRST_FRAME_LOAD: 'Load 360° Video First Frame',
  VIDEO_360_LOOP: '360º Video Loop',
  VIDEO_360_PAUSE: '360º Video Pause',
  VIDEO_360_PROGRESS: '360º Video Progress',
  VIDEO_360_START: '360º Video Start',
  VR_MODE: 'VR',
};

export const GA_VIDEO_TYPES = {
  PROGRESSIVE_DOWNLOAD: '(Progressive Download)',
  ADAPTIVE_STREAMING: '(Adaptive Streaming)',
};

export const consentDenied = {
  ad_storage: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'denied',
  wait_for_update: 500,
};

export const consentGranted = {
  ad_storage: 'granted',
  analytics_storage: 'granted',
  functionality_storage: 'granted',
  personalization_storage: 'granted',
  security_storage: 'granted',
  wait_for_update: 500,
};

export const consentNecessary = {
  ad_storage: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'granted',
  personalization_storage: 'denied',
  security_storage: 'granted',
  wait_for_update: 500,
};

export const getConsentFromQueryParams = () => {
  const consent = getSearchParam(searchParams.GTAG_CONSENT);
  switch (consent) {
    case gtagConsentParamValues.GRANTED:
      return consentGranted;
    case gtagConsentParamValues.DENIED:
      return consentDenied;
    case gtagConsentParamValues.NECESSARY:
      return consentNecessary;
    default:
      return null;
  }
}

export const setConsent = (consent = consentDenied) => {
  ReactGA.gtag('consent', 'default', consent);
  saveConsentToLocalStorage(consent);
};

export const updateConsent = (consent = consentDenied) => {
  const savedConsent = getConsentFromLocalStorage();
  if (savedConsent && isEqual(consent, savedConsent)) return;
  ReactGA.gtag('consent', 'update', consent);
  saveConsentToLocalStorage(consent);
};

const eventKeysSet = new Set(allowedEventKeys);

const filterAllowedEventKeys = (eventParams = {}) => {
  return Object.keys(eventParams).reduce((params, key) => {
    if (eventKeysSet.has(key)) {
      params[key] = eventParams[key];
    }
    return params;
  }, {});
};

export const buildCustomParams = eventParams => {
  const {
    currentStopName,
    currentStopNumber,
    tours,
  } = store?.getState()?.publicTour;
  if (!tours?.[_tourId]) return {};
  const { name: currentTourName } = tours ? tours[_tourId] : {};
  return {
    ...eventParams,
    event_category: `${_tourId}-${currentTourName}`,
    account_id: _accountId,
    tour_id: _tourId,
    stop_number: currentStopNumber,
    stop_name: currentStopName,
    event_label: eventParams.label || undefined,
  };
};
/**
 * @param eventParams Object - Includes "label" and "action" key/value pairs
 */
export const customGaEvent = eventParams => {
  const params = buildCustomParams(eventParams);
  ReactGA.gtag('event', params.action, filterAllowedEventKeys(params));
};

export const gaModalView = modalName => {
  ReactGA.modalview(modalName);
};

export const gaPageView = path => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

// ga page views get registerd on history change
// need to call this for UA to get page views to register on page changes
export const gaUAPageView = path => {
  ReactGA.send({
    hitType: 'pageview',
    page: path,
    send_to: uaGroup,
  });
};

export const gaInit = ({
  accountId,
  analyticsContainerId,
  clientGA4Id,
  clientId,
  stopName = '',
  stopNumber = 0,
  tourId,
  tourName,
}) => {
  _accountId = accountId;
  _tourId = tourId;
  if (analyticsContainerId) {
    TagManager.initialize({ gtmId: analyticsContainerId });
  }
  if (clientId) {
    clients = [...clients, { trackingId: clientId, group: uaGroup }];
  }
  if (clientGA4Id) {
    clients = [...clients, { trackingId: clientGA4Id, group: ga4Group }];
  }
  setConsent(getConsentFromLocalStorage() ?? getConsentFromQueryParams() ?? consentDenied);
  const gtagOptions = group => ({
    transport_url: process.env.REACT_APP_C3D_ANALYTICS_BE,
    custom_map: dimensions,
    account_id: accountId,
    tour_id: tourId,
    tour_name: tourName,
    stop_number: stopNumber,
    stop_name: stopName,
    anonymize_ip: true,
    cookie_flags: 'secure;samesite=none',
    send_page_view: false,
    groups: ['default', group],
  });
  ReactGA.initialize([
    {
      // deprecates July 2023
      trackingId: process.env.REACT_APP_DEFAULT_GA_ACCOUNT,
      titleCase: false,
      gtagOptions: gtagOptions(uaGroup),
    },
    {
      trackingId: process.env.REACT_APP_C3D_GA4_ID,
      titleCase: false,
      gtagOptions: gtagOptions(ga4Group),
    },
    ...clients.map(({ trackingId, group }) => ({
      trackingId,
      titleCase: false,
      gtagOptions: gtagOptions(group),
    })),
  ]);
  gaPageView(window.location.pathname);
};

/**
 * @param {String} gtmId  - gtm id to initialize
 */
export const initGTM = gtmId => {
  TagManager.initialize({ gtmId });
};

/**
 * @param {Number} totalTours  - total tours an account has
 * @param {Number} totalActiveStops - total stops an account has
 */
export const gtmDataLayer = (totalTours, totalActiveStops) => {
  const { account, userCredentials } = store.getState();
  const {
    userId,
    firstname,
    lastname,
    username,
    email,
    identityProviders,
    userCreatedDate,
  } = userCredentials;
  const { identityProvider = 'concept3d' } = identityProviders[0] || {};
  const { tier, tourAccountName, tourAccountId, permissions } = account;
  const { maxTours, maxStops } = permissions;
  const userName =
    firstname && lastname ? `${firstname} ${lastname}` : username;

  TagManager.dataLayer({
    dataLayer: {
      event: 'send_c3d_data',
      accountName: tourAccountName,
      accountId: tourAccountId,
      accountTier: tier,
      numberOfCurrentTours: totalTours,
      numberOfMaxTours: maxTours,
      numberOfCurrentTourStops: totalActiveStops,
      numberOfMaxTourStops: maxStops,
      userName,
      userEmail: email,
      userId,
      userType: identityProvider,
      userCreatedDate,
    },
  });
};
