export const initialState = {
  accountsList: [],
  active: true,
  tourAccountId: '',
  tier: '',
  trialExpiration: null,
  status: 'active',
  stripeId: '',
  permissions: {},
  payment: {},
  invoices: [],
  stripeError: '',
  swapError: '',
  updating: false,
  gaId: null,
  analyticsMeasurementId: null,
  analyticsContainerId: null,
  captureHigherEdTrackingId: null,
  mapboxToken: '',
  error: '',
};

function accounts(state = initialState, action = {}) {
  switch (action.type) {
    case 'SAVE_ACCOUNT_INFO': {
      return Object.assign({}, state, {
        stripeId: action.tier === 'foundation' ? action.stripeId : null,
        tier: action.tier,
        status: action.status,
        payment: action.tier === 'foundation' ? action.payment : null,
        permissions: action.permissions,
        packageQuantity: action.packageQuantity,
        tourAccountId: action.aid,
        tourAccountName: action.name,
        gaId: action.gaId,
        analyticsMeasurementId: action.analyticsMeasurementId,
        analyticsContainerId: action.analyticsContainerId,
        captureHigherEdTrackingId: action.captureHigherEdTrackingId,
        mapboxToken: action.mapboxToken,
        trialExpiration: action.trialExpiration,
      });
    }
    case 'UPDATE_PACKAGE_QUANTITY': {
      return Object.assign({}, state, {
        packageQuantity: action.quantity,
        permissions: action.permissions,
      });
    }
    case 'SAVE_PAYMENT_INFO': {
      return Object.assign({}, state, {
        payment: action.data,
      });
    }
    case 'SAVE_INVOICE_HISTORY': {
      return Object.assign({}, state, {
        invoices: action.data,
      });
    }
    case 'SET_STRIPE_ERROR': {
      return Object.assign({}, state, {
        stripeError: action.error,
      });
    }
    case 'ACCOUNT_UPDATING': {
      return Object.assign({}, state, { updating: !state.updating });
    }
    case 'SAVE_ALL_ACCESSIBLE_ACCOUNTS':
      return Object.assign({}, state, {
        accountsList: action.accounts,
      });
    case 'TOUR_ACCOUNT_SWAP_ERROR': {
      return Object.assign({}, state, {
        swapError: action.value ? 'Erorr Occured When Swapping Accounts' : '',
      });
    }
    case 'UPDATE_GAID': {
      return { ...state, gaId: action.gaId };
    }
    case 'SAVE_MEASUREMENT_ID': {
      return {
        ...state,
        analyticsMeasurementId: action.analyticsMeasurementId,
      };
    }
    case 'SAVE_CONTAINER_ID': {
      return {
        ...state,
        analyticsContainerId: action.analyticsContainerId,
      };
    }
    case 'SAVE_CAPTURE_HIGHER_ED_TRACKING_ID': {
      return {
        ...state,
        captureHigherEdTrackingId: action.captureHigherEdTrackingId,
      };
    }
    case 'FETCH_TOUR_ACCOUNT_ERROR': {
      return Object.assign({}, state, {
        error: action.errorMessage,
      });
    }
    default:
      return state;
  }
}

export default accounts;
