export const initialState = {
  mediaFetched: false,
  mediaFetching: 'fetch_success',
  mediaUploading: false,
  rawUploading: false,
  mediaBuilding: false,
  mediaSaving: false,
  mediaDeleting: false,
  mediaUploadFailed: true,
  mediaDeleteFailed: true,
  error: {},
  panos: {},
  images: {},
  videos: {},
  videos360: {},
  audio: {},
  icons: {},
  isMainMediaPlaying: false,
};

function _media(state = initialState, action = {}) {
  const {
    type,
    pano,
    panos,
    images,
    icons,
    error,
    videos,
    videos360,
    audio,
    mediaType,
    media,
    isMainMediaPlaying,
  } = action;
  switch (type) {
    case 'MEDIA_FETCHING': {
      return { ...state, mediaFetching: 'fetching' };
    }
    case 'MEDIA_FETCH_ERROR': {
      return { ...state, mediaFetching: 'fetch_error', error };
    }
    case 'MEDIA_FETCH_SUCCESS': {
      return { ...state, mediaFetched: true, mediaFetching: 'fetch_success' };
    }
    case 'START_MEDIA_UPLOAD': {
      return { ...state, mediaUploading: true };
    }
    case 'START_RAW_UPLOAD': {
      return { ...state, rawUploading: true };
    }
    case 'START_MEDIA_BUILD': {
      return { ...state, rawUploading: false, mediaBuilding: true };
    }
    case 'START_MEDIA_SAVE': {
      return { ...state, mediaBuilding: false, mediaSaving: true };
    }
    case 'MEDIA_UPLOAD_COMPLETE': {
      return { ...state, mediaUploading: false, mediaSaving: false };
    }
    case 'MEDIA_UPLOAD_FAILED': {
      return {
        ...state,
        mediaUploading: false,
        mediaBuilding: false,
        mediaUploadFailed: true,
        error,
      };
    }
    case 'MEDIA_FAIL_RESET': {
      return {
        ...state,
        mediaUploadFailed: false,
        mediaDeleteFailed: false,
        error: {},
      };
    }
    case 'MEDIA_DELETING': {
      return { ...state, mediaDeleting: true };
    }
    case 'MEDIA_DELETED': {
      return { ...state, mediaDeleting: false };
    }
    case 'MEDIA_DELETE_FAILED': {
      return { ...state, mediaDeleting: false, mediaDeleteFailed: true };
    }
    case 'SAVE_ACCOUNT_PANO': {
      const [newPano] = Object.values(panos).filter(
        pano => !state.panos[pano.id]
      );
      return {
        ...state,
        panos: {
          ...state.panos,
          [newPano.id]: newPano,
        },
      };
    }
    case 'UPDATE_ACCOUNT_PANO': {
      return {
        ...state,
        panos: {
          ...state.panos,
          [pano.id]: { ...state.panos[pano.id], ...pano },
        },
      };
    }
    case 'SAVE_ACCOUNT_MEDIA': {
      const [newMedia] = Object.values(media).filter(
        mediaEntry => !state[mediaType][mediaEntry.id]
      );
      return {
        ...state,
        [mediaType]: {
          ...state[mediaType],
          [newMedia.id]: newMedia,
        },
      };
    }
    case 'UPDATE_ACCOUNT_MEDIA': {
      return {
        ...state,
        [mediaType]: {
          ...state[mediaType],
          [media.id]: { ...state[mediaType][media.id], ...media },
        },
      };
    }
    case 'SAVE_ALL_ACCOUNT_MEDIA': {
      return {
        ...state,
        panos,
        images,
        videos,
        videos360,
        audio,
        icons,
      };
    }
    case 'TOGGLE_MAIN_MEDIA_STATUS': {
      return {
        ...state,
        isMainMediaPlaying,
      };
    }
    default:
      return state;
  }
}

export default _media;
