import history from '../browserHistory';
import { buildAuthHeaders } from '../utils/buildAuthHeaders';
import keycloak from '../keycloak';
import { handleResponse } from './helpers/helpers';

export const fetchTourAccountData = accountId => {
  const myHeaders = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_TOUR_API}/accounts/${encodeURIComponent(
      accountId
    )}`,
    { headers: myHeaders, credentials: 'include' }
  )
    .then(res => {
      if (!res.ok) {
        throw new Error(`Failed to fetch account ${accountId}`);
      }
      return res.json();
    })
    .then(data => {
      return data;
    });
};

//write api call to upgrade account
export const upgradeAccount = accountData => {
  const {
    accountId,
    userId,
    packageQuantity,
    newTier,
    accountName,
  } = accountData;
  let myHeaders = buildAuthHeaders();
  return fetch(
    `${
      process.env.REACT_APP_ACCOUNTS_API
    }/api/admin/accounts/tour/upgrade/${encodeURIComponent(userId)}`,
    {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(accountData),
      credentials: 'include',
    }
  )
    .then(res => {
      return res.json();
    })
    .then(data => {
      if (typeof data === 'string') {
        // return error
        return data;
      } else {
        const { stripeResponse, newPermissions } = data;
        const customer = stripeResponse;
        history.push('/home/settings/subscription');
        return Object.assign(
          {},
          {
            name: accountName,
            aid: accountId,
            stripeId: customer.id,
            tier: newTier,
            packageQuantity,
            tierPermissions: newPermissions,
            customer,
          }
        );
      }
    });
};

export const fetchPaginatedTourAccounts = (startKey, search) => {
  const myHeaders = new Headers();
  const token = keycloak.token;
  myHeaders.append('Authorization', `Bearer ${token}`);
  const queryParams = [];
  if (search) {
    const encodedSearchTerm = encodeURIComponent(search);
    queryParams.push(`search=${encodedSearchTerm}`);
  }
  if (startKey) queryParams.push(`start=${startKey}`);
  const fetchUrl = queryParams.length
    ? `${
        process.env.REACT_APP_TOUR_API
      }/admin/v2/accounts?sort=name asc, tier asc&${queryParams.join('&')}`
    : `${process.env.REACT_APP_TOUR_API}/admin/v2/accounts?sort=name asc, tier asc`;
  return fetch(encodeURI(fetchUrl), {
    headers: myHeaders,
    credentials: 'include',
  })
    .then(res => res.json())
    .then(data => data)
    .catch(e => console.log({ e }));
};

export const fetchTourAccountFromC3D = async (aid, noAuth = false) => {
  const myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${
      process.env.REACT_APP_ACCOUNTS_API
    }/api/admin/v2/accounts/${encodeURIComponent(aid)}`,
    {
      headers: myHeaders,
      credentials: 'include',
    }
  );
  return await handleResponse(res, noAuth);
};

export const editTourAnalyticsID = (aid, accountData) => {
  const headers = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/v2/${encodeURIComponent(
      aid
    )}/settings/ga`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(accountData),

      credentials: 'include',
    }
  )
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.error(err));
};

export const editTourAnalyticsMeasurementId = (aid, accountData) => {
  const headers = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/v2/${encodeURIComponent(
      aid
    )}/settings/analyticsMeasurementId`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(accountData),
      credentials: 'include',
    }
  )
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.error(err));
};

export const editTourAnalyticsContainerId = (aid, accountData) => {
  const headers = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/v2/${encodeURIComponent(
      aid
    )}/settings/analyticsContainerId`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(accountData),
      credentials: 'include',
    }
  )
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.error(err));
};

export const editCaptureHigherEdId = (aid, accountData) => {
  const headers = buildAuthHeaders();
  return fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/v2/${encodeURIComponent(
      aid
    )}/settings/captureHigherEdTrackingId`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(accountData),
      credentials: 'include',
    }
  )
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.error(err));
};

export const editTourAccount = (aid, editedData) => {
  const headers = buildAuthHeaders();
  return fetch(
    `${
      process.env.REACT_APP_ACCOUNTS_API
    }/api/admin/accounts/tour/${encodeURIComponent(aid)}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(editedData),
      credentials: 'include',
    }
  )
    .then(res => res.json())
    .then(data => data)
    .catch(err => console.error(err));
};
