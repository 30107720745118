import {
  newPaymentSource,
  updateStripePaymentSource,
  stripePaymentHistory,
} from '../api/stripe';
import {
  upgradeAccount,
  fetchPaginatedTourAccounts,
  editTourAnalyticsID,
  editTourAnalyticsMeasurementId,
  editTourAnalyticsContainerId,
  editCaptureHigherEdId,
} from '../api/accounts';
import { upgradeAllPublicTours } from './publicTourActions';
import { batch } from 'react-redux';

const savePaymentInfo = data => {
  return {
    type: 'SAVE_PAYMENT_INFO',
    data,
  };
};

const saveInvoiceHistory = data => {
  return {
    type: 'SAVE_INVOICE_HISTORY',
    data,
  };
};

export const setStripeError = error => {
  return {
    type: 'SET_STRIPE_ERROR',
    error,
  };
};

export const saveAccountInfo = tourAccount => {
  return {
    type: 'SAVE_ACCOUNT_INFO',
    stripeId: tourAccount.stripeId,
    status: tourAccount.status,
    tier: tourAccount.tier,
    packageQuantity: tourAccount.packageQuantity,
    permissions: tourAccount.customPermissions || {},
    payment: tourAccount.customer,
    aid: tourAccount.aid,
    name: tourAccount.name,
    gaId: tourAccount.gaId,
    analyticsMeasurementId: tourAccount.analyticsMeasurementId,
    analyticsContainerId: tourAccount.analyticsContainerId,
    captureHigherEdTrackingId: tourAccount.captureHigherEdTrackingId,
    mapboxToken: tourAccount.mapboxToken,
    trialExpiration: tourAccount.trialExpiration,
  };
};

export const accountUpdating = () => {
  return {
    type: 'ACCOUNT_UPDATING',
  };
};

const saveAllAccessibleAccounts = accounts => {
  return {
    type: 'SAVE_ALL_ACCESSIBLE_ACCOUNTS',
    accounts,
  };
};

const updateGaId = gaId => {
  return {
    type: 'UPDATE_GAID',
    gaId,
  };
};

export const saveUpdatedAnalyticsMeasurementId = analyticsMeasurementId => {
  return {
    type: 'SAVE_MEASUREMENT_ID',
    analyticsMeasurementId,
  };
};

export const saveUpdatedAnalyticsContainerId = analyticsContainerId => {
  return {
    type: 'SAVE_CONTAINER_ID',
    analyticsContainerId,
  };
};

export const saveUpdatedCaptureHigherEdTrackingId = captureHigherEdTrackingId => {
  return {
    type: 'SAVE_CAPTURE_HIGHER_ED_TRACKING_ID',
    captureHigherEdTrackingId,
  };
};

export const fetchTourAccounts = (startKey, search) => {
  return dispatch => {
    fetchPaginatedTourAccounts(startKey, search).then(first10accounts => {
      dispatch(saveAllAccessibleAccounts(first10accounts));
    });
  };
};

export const upgradeTourAccount = data => {
  return dispatch => {
    dispatch(accountUpdating());
    upgradeAccount(data).then(response => {
      dispatch(upgradeAllPublicTours(data.newTier));
      if (typeof response === 'string') {
        batch(() => {
          dispatch(setStripeError(response));
          dispatch(accountUpdating());
        });
      } else {
        batch(() => {
          dispatch(saveAccountInfo(response));
          dispatch(accountUpdating());
        });
      }
    });
  };
};

export const updatePaymentSource = data => {
  return dispatch => {
    dispatch(accountUpdating());
    updateStripePaymentSource(data).then(response => {
      if (typeof response === 'string') {
        batch(() => {
          dispatch(setStripeError(response));
          dispatch(accountUpdating());
        });
      } else {
        batch(() => {
          dispatch(savePaymentInfo(response));
          dispatch(accountUpdating());
        });

        typeof data.callback === 'function' && data.callback();
      }
    });
  };
};

export const addNewPaymentSource = data => {
  return dispatch => {
    dispatch(accountUpdating());
    newPaymentSource(data).then(response => {
      if (typeof response === 'string') {
        batch(() => {});
        dispatch(setStripeError(response));
        dispatch(accountUpdating());
      } else {
        batch(() => {
          dispatch(savePaymentInfo(response));
          dispatch(accountUpdating());
        });

        typeof data.callback === 'function' && data.callback();
      }
    });
  };
};

export const getPaymentHistory = stripeId => {
  return dispatch => {
    stripePaymentHistory(stripeId).then(response => {
      dispatch(saveInvoiceHistory(response));
    });
  };
};

export const addGAAnalyticsID = (aid, data) => {
  return dispatch => {
    dispatch(accountUpdating());
    editTourAnalyticsID(aid, data).then(res => {
      batch(() => {
        dispatch(updateGaId(res.gaId));
        dispatch(accountUpdating());
      });
    });
  };
};

export const editGAMeasurementID = (aid, data) => {
  return dispatch => {
    dispatch(accountUpdating());
    editTourAnalyticsMeasurementId(aid, data).then(res => {
      batch(() => {
        dispatch(saveUpdatedAnalyticsMeasurementId(res.analyticsMeasurementId));
        dispatch(accountUpdating());
      });
    });
  };
};

export const editGAContainerID = (aid, data) => {
  return dispatch => {
    dispatch(accountUpdating());
    editTourAnalyticsContainerId(aid, data).then(res => {
      batch(() => {
        dispatch(saveUpdatedAnalyticsContainerId(res.analyticsContainerId));
        dispatch(accountUpdating());
      });
    });
  };
};

export const editCaptureHigherEdTrackingId = (aid, data) => {
  return dispatch => {
    dispatch(accountUpdating());
    editCaptureHigherEdId(aid, data).then(res => {
      batch(() => {
        dispatch(
          saveUpdatedCaptureHigherEdTrackingId(res.captureHigherEdTrackingId)
        );
        dispatch(accountUpdating());
      });
    });
  };
};
