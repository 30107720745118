import { buildAuthHeaders } from '../utils/buildAuthHeaders';
import { handleShareError, handleResponse } from './helpers/helpers';

export const saveTourToDatabase = tourData => {
  let myHeaders = buildAuthHeaders();
  return fetch(`${process.env.REACT_APP_TOUR_API}/tours`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(tourData),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data;
    });
};

export const deleteTourFromDatabase = tourData => {
  let myHeaders = buildAuthHeaders();
  return fetch(`${process.env.REACT_APP_TOUR_API}/tours`, {
    method: 'DELETE',
    headers: myHeaders,
    body: JSON.stringify(tourData),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data;
    });
};

export const saveCustomTourStyles = styles => {
  let myHeaders = buildAuthHeaders();
  return fetch(`${process.env.REACT_APP_TOUR_API}/tourstyles`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(styles),
    credentials: 'include',
  })
    .then(res => res.json())
    .then(data => data);
};

/**
 * @deprecated Use `v2/publishedTours` instead.
 */
export const publishTourToDatabase = tourData => {
  const data = {
    aid: tourData.aid,
    id: tourData.id,
    tourData: {
      [tourData.id]: tourData.tourData,
    },
    published: tourData.published,
    uptoDate: tourData.uptoDate,
    tier: tourData.tier,
    gaId: tourData.gaId,
    mapboxToken: tourData.mapboxToken,
  };
  let myHeaders = buildAuthHeaders();
  return fetch(`${process.env.REACT_APP_TOUR_API}/publishtour`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data;
    });
};

/**
 * @deprecated Use `v2/publishedTours` instead.
 */
export const fetchPublicTourFromDatabase = async id => {
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_TOUR_API}/publishtour/${id}`,
    {
      headers: myHeaders,
    }
  );
  handleShareError(res);
  return await handleResponse(res);
};

export const setPublishedTourTier = async (tourId, tier) => {
  const data = {
    id: tourId,
    tier,
  };
  let myHeaders = buildAuthHeaders();
  return fetch(`${process.env.REACT_APP_TOUR_API}/publishtour/newTier/`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data;
    });
};
